<template>
  <div>
    <AssignmentsList
      :assignments="assignments"
      :variation="variation"
    >
      <template #default="{ assignment }">
        <slot :assignment="assignment" />
      </template>
    </AssignmentsList>
  </div>
</template>

<script>
import AssignmentsList from './AssignmentsList'

export default {
  name: 'StudentsAssignmentsList',
  components: { AssignmentsList },
  props: {
    assignments: {
      type: Array,
      required: true,
    },
    variation: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
