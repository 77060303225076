<template>
  <div>
    <StudentsAssignmentsList :assignments="assignments">
      <template #default="{ assignment }">
        <div>
          <s-button
            variation="secondary"
            @click="goToReport(assignment)"
          >
            {{ $t('commons.seeReport') }}
          </s-button>
        </div>
      </template>
    </StudentsAssignmentsList>
  </div>
</template>

<script>
import StudentsAssignmentsList from './StudentsAssignmentsList'

export default {
  name: 'HistoricAssignmentList',
  components: {
    StudentsAssignmentsList,
  },
  props: {
    assignments: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goToReport(assignment) {
      const { questionnaireCode } = assignment
      this.$router.push({
        name: 'lecture-report',
        params: {
          questionnaireCode,
        },
      })
    },
  },
}
</script>
