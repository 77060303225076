<template>
  <div class="proposed-assignment-historic">
    <Header
      :title="title"
      :custom-style="subjectStyleHeader"
      :subtitle="$t('proposedAssignmentHistoric.subtitle')"
      :navigation="navigation"
      :loading="proposedAssignments.loading"
    />
    <wrapper class="proposed-assignment-historic__wrapper">
      <AssignmentsSectionSkeleton v-if="proposedAssignments.loading" />
      <AssignmentSectionLayout
        v-else
        :title="$t('highSchool.subject.historicAssignmentsSection.title')"
        :subtitle="$t('highSchool.subject.historicAssignmentsSection.subtitle')"
      >
        <HistoricAssignmentList :assignments="proposedAssignments.data" />
      </AssignmentSectionLayout>
    </wrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import proposedAssignmentApi from '@/service/proposedAssignment'
import subject from '@/mixins/subject'
import subjectStyleHeader from '@/mixins/subjectStyleHeader'
import mediaQueries from '@/mixins/mediaQueries'
import AssignmentsSectionSkeleton from '@/components/Assignments/AssignmentsSectionSkeleton'
import AssignmentSectionLayout from '@/components/Assignments/AssignmentSectionLayout'
import Header from '@/components/Header/Header'
import HistoricAssignmentList from '@/components/Assignments/HistoricAssignmentList'

export default {
  name: 'ProposedAssignmentHistoric',
  components: {
    Header,
    HistoricAssignmentList,
    AssignmentSectionLayout,
    AssignmentsSectionSkeleton,
  },
  mixins: [ mediaQueries, subject, subjectStyleHeader ],
  data() {
    return {
      proposedAssignments: {
        loading: false,
        data: [],
        error: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'subject',
    ]),
    title() {
      if (this.subject.ready) {
        return this.subject.data.name
      }

      return ''
    },
    navigation() {
      return {
        label: this.$t('commons.back'),
        route: {
          name: 'lecture-assignments',
        },
      }
    },
  },
  created() {
    this.fetchProposedAssignments()
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'lecture-assignments',
      })
    },
    async fetchProposedAssignments() {
      this.proposedAssignments.loading = true

      try {
        const response = await proposedAssignmentApi.getProposedAssignments(this.subjectId)
        this.proposedAssignments.data = response.data
      } catch (error) {
        this.proposedAssignments.error = error
      } finally {
        this.proposedAssignments.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.proposed-assignment-historic {
  width: 100%;
  min-height: calc(100vh - #{$menu-height});

  &__wrapper {
    padding: {
      top: $size-l;
      bottom: $end-space;
    }
  }

  ::v-deep .header {
    &__toolbar {
      border-bottom: none;
    }

    .h5 {
      color: transparentize($color-white, 0.2);
      font-size: $font-size-heading-5;

      @include mq-s--mf {
        font-size: $font-size-heading-4;
      }
    }
  }
}
</style>
